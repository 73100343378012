import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { COLOR_PRIMARY, COLOR_GREEN, COLOR_RED, COLOR_WHITE } from '../styles/colors';
import { FONT_NUNITO } from '../styles/fonts';

interface ToastProps {
  className?: string;
  visible: boolean;
  type?: ToastType;
  children: any;
  onHide: () => void;
}

export enum ToastType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const useStyles = makeStyles((theme: Theme) => ({
  toast: {},

  info: {},
  success: {
    '& $alert': {
      background: COLOR_GREEN,
    },
  },
  error: {
    '& $alert': {
      background: COLOR_RED,
    },
  },

  alert: {
    background: COLOR_PRIMARY,
  },
  message: {
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_WHITE,
  },
}), { name: Toast.name });

export default function Toast (props: ToastProps) {
  const {
    className,
    children,
    visible,
    type = ToastType.INFO,
    onHide,
  } = props;
  const classes = useStyles();

  function onClose (event?: React.SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') return;

    onHide();
  }

  return (
    <Snackbar
      className={clsx(
        classes.toast,
        type === ToastType.INFO && classes.info,
        type === ToastType.SUCCESS && classes.success,
        type === ToastType.ERROR && classes.error,
        className,
      )}
      autoHideDuration={5000}
      open={visible}
      onClose={onClose}
    >
      <MuiAlert
        className={classes.alert}
        classes={{
          message: classes.message
        }}
        severity={type.toLowerCase() as any}
        elevation={6}
        variant='filled'
        onClose={onClose}
      >
        {children}
      </MuiAlert>
    </Snackbar>
  );
}
