const apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDJjOWQ4NDEzMjlkNTczNzM4OWMwNTVlMTA1MUZlMTBjNTJEM0RGYzQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTYyMzkxNzc0MjU2MywibmFtZSI6ImRlbW8ifQ.ZCnibZRgFy-T7Y30wLkEz6Ja8SHcawTkF4wjVYibYYs';

const API_URL = 'https://api.nft.storage/upload';
export function uploadImage(file:any):Promise<any> {
  return fetch(API_URL, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${apiKey}`,
    },
    body: file,
  })
    .then(res => {
      if (res.ok) return res.json();

      throw new Error('Failed to get GQL response');
    })
    .then((data:any) => {
      const result = data.value;
      const errorMessage = data.errors && data.errors[0].message;

      return {
        ...result,
        errorMessage,
      };
    })
    .catch(console.error);
}
