import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { FormControlLabel, Checkbox, Radio, FormLabel } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import Dialog from '../molecules/Dialog';
import InputNumber from '../atoms/InputNumber';
import Button from '../atoms/Button';
import Loader from '../atoms/Loader';
import AllAssetTabBar, { TABS, TabType } from '../atoms/AllAssetTabBar';
import Input from '../atoms/Input';
import Toast, { ToastType } from '../atoms/Toast';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { weiToEther, etherToWei } from '../services/helper';
import { WEI_CURRENCY, ETH_CURRENCY, WEI_RATIO, ETH_RATIO } from '../services/constants';
import {
  COLOR_GREEN,
  COLOR_PRIMARY,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  COLOR_TEXT,
  COLOR_SHADOW,
  COLOR_RED_ERROR,
} from '../styles/colors';
import { FONT_NUNITO } from '../styles/fonts';
import User from '../types/User';
import moment from "moment";

interface AllAssetsProps {
  user: User;
}

export const assetCategoryMapping:{[key: string]: string} = {
  '/menu/gold': 'gold',
  '/menu/diamond': 'diamond',
  '/menu/platinum': 'platinum',
};

export const RARITY = {
  DIAMOND: 'diamond',
  GOLD: 'gold',
  PLATINUM: 'platinum',
  MOMENT: 'moment',
};

export const RARITY_TITLE = {
  [RARITY.DIAMOND]: 'Diamond',
  [RARITY.GOLD]: 'Gold',
  [RARITY.PLATINUM]: 'Platinum'
};

export const categoryMpping:{[key: string]: any} = {
  [RARITY.GOLD]: {
    name:"menu",
    parent:"/menu",
    category: "/menu/gold"
  },
  [RARITY.PLATINUM]: {
    name:"menu",
    parent:"/menu",
    category: "/menu/platinum"
  },
  [RARITY.DIAMOND]: {
    name:"menu",
    parent:"/menu",
    category: "/menu/diamond"
  },
  [RARITY.MOMENT]: {
    name:"moment",
    parent:"/moment",
    category: "/moment/moment"
  }
};

const useStyles = makeStyles(theme => ({
  allAssets: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
    position: 'relative',
  },
  allAssetTabBar: {},
  assetSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
  },
  assetSectionHeading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${COLOR_SHADOW}`,
    color: COLOR_LIGHT_GRAY,
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  noData: {
    width: '100%',
    fontSize: theme.typography.pxToRem(20),
    color: COLOR_PRIMARY,
    textAlign: 'center',
  },
  card: {
    width: '284px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    margin: `0 16px 32px 16px`,
    transition: 'all .3s ease',
    padding: theme.spacing(2)
  },
  assetImage: {
    width: '100%',
    height:'300px',
    borderRadius: '16px',
    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25);`,
  },
  assetInfo: {
    width: '100%',
    padding: theme.spacing(1, 0),
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  separator: {
    margin: theme.spacing(1, 0),
    height:"1px",
    borderRadius:'1px',
    background:"#E6E8EC"
  },
  assetPrice: {
    color: COLOR_GREEN,
    border: `2px solid ${COLOR_GREEN}`,
    borderRadius: '4px',
    padding: theme.spacing(0.4),
    fontWeight: 600,
  },
  assetName: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
  btn: {
    marginTop:"-5px",
    color: COLOR_WHITE,
    backgroundColor: COLOR_PRIMARY,
    borderRadius: '90px',
    width: '124px',
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    padding:"8px 12px",
    top:"71px"
  },
  actions: {
    width: '100%',
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  updateAssetModal: {
    position: 'relative',
  },
  modalContent: {
    display: 'flex',
  },
  leftContainer: {
    width: '28%',
    display: 'flex',
    flexDirection: 'column'
  },
  rightContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(4),
  },
  textContent: {
    padding: theme.spacing(1, 0),
  },
  publishButton: {
    width: '170px',
    color: COLOR_WHITE,
    backgroundColor: COLOR_GREEN,
    border: `2px solid ${COLOR_GREEN}`,
    borderRadius: '30px',
    padding: theme.spacing(1.5),
    lineHeight: '25px',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    marginBottom: theme.spacing(2),

    '&:hover': {
      color: COLOR_WHITE,
      backgroundColor: COLOR_GREEN,
      border: `2px solid ${COLOR_GREEN}`,
    }
  },
  disabledButton: {
    backgroundColor: COLOR_LIGHT_GRAY,
    color: COLOR_TEXT,
    border: `2px solid ${COLOR_LIGHT_GRAY}`,

    '&:hover': {
      color: COLOR_TEXT,
      backgroundColor: COLOR_LIGHT_GRAY,
      border: `2px solid ${COLOR_LIGHT_GRAY}`,
    }
  },
  input: {
    marginTop: theme.spacing(1),
    borderRadius: '4px',
  },
  checkbox: {
    color: COLOR_PRIMARY,

    '& $checkIconWrapper': {
      background: COLOR_PRIMARY,
      borderColor: COLOR_PRIMARY,
    }
  },
  galleryCheckbox: {
    padding: 0
  },
  categoryContainer: {},
  priceContainer: {},
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    marginBottom: theme.spacing(1.5),
  },
  items: {
    display: 'flex',
  },
  checkboxContainer: {
    width: '50%',
  },
  checkboxLabel: {},
  radioContainer: {
    width: '33%',
  },
  radioLabel: {},
  priceDiv: {
    // width: '120px',
  },
  price: {},
  currency: {},
  priceInput: {
    borderRadius: '4px',
  },
  inputField: {
    padding: theme.spacing(1),
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    width:"200px",
    color: COLOR_TEXT,
   
  },
  error: {
    color: COLOR_RED_ERROR,
    fontSize: '12px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  rarityLabel: {
    color: COLOR_TEXT,
    fontSize: '1.125rem',
    fontWeight: 700
  },
  pendingMsg: {
    border: `2px solid ${COLOR_RED_ERROR}`,
    borderRadius: '10px',
    color: COLOR_RED_ERROR,
    display: 'flex',
    fontSize: '20px',
    fontWeight: 600,
    justifyContent: 'center',
    margin: '50px',
    padding: '20px',
  }
}), { name: AllAssets.name });

function AllAssets (props:AllAssetsProps) {
  const classes = useStyles();
  const { user } = props;
  const {tab = 0} = useParams<any>();
  const history = useHistory();

  const [allAssets, setAllAssets] = useState<any>(false);
  const [assetsToRender, setAssetsToRender] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<any>();
  const [isMoment, setIsMoment] = useState<boolean>(false);
  const [assetPriceError, setAssetPriceError] = useState<string>('');
  const [scoreError, setScoreError] = useState<string>('');
  const [centuryError, setCenturyError] = useState<string>('');
  const [againstError, setAgainstError] = useState<string>('');
  const [dateError, setDateError] = useState<string>('');

  const [selectedCategory, setSelectedCategory] = useState<string>(RARITY.DIAMOND);
  const [assetPrice, setAssetPrice] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(tab);
  const [category, setCategory] = useState<string>(TABS[tab]);
  const [score, setScore] = useState<string>('');
  const [century, setCentury] = useState<string>('');
  const [against, setAgainst] = useState<string>('');
  const [datePlayed, setDatePlayed] = useState<string>('');

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);

  useEffect(() => {
    fetchAllAssetsData();
  }, [dialogOpen, category]);

  useEffect(() => {
    if (selectedAsset) {
      updateAssetData();
    }
  }, [selectedAsset, dialogOpen]);

  useEffect(() => {
    setCategory(TABS[tab]);
    setSelectedTab(tab);
  }, [tab]);

  useEffect(() => {
    if (allAssets && allAssets.length) {
      setLoading(true);
      setAssetsToRender(allAssets
        .filter((asset:any) => category !== TabType.minted ? (asset.status === category): (asset.status === TabType.minted || asset.status === TabType.pending))//([TabType.minted, TabType.pending].findIndex(asset.status) !== -1))
        .sort((a:any, b:any) => {return ((new Date(b.creationDate)).getTime() - (new Date(a.creationDate)).getTime());})
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [category, allAssets]);

  useEffect(() => {
    if (!dialogOpen) {
      clearFormData();
    }
  }, [dialogOpen]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  function updateAssetData() {
    if (selectedAsset.category && selectedAsset.category.length) {
      selectedAsset.category.forEach((item:any) => {
        if (item.name === 'moment') {
          setIsMoment(true);

          // if (selectedAsset.attributes && selectedAsset.attrributes.length) {
          //   const allAttributes = selectedAsset.attrributes[0];
          //   setScore(allAttributes.);
          //   setAgainst();
          //   setCentury();
          //   setDatePlayed();
          // }
        } else {
          setSelectedCategory(assetCategoryMapping[item.category]);
        }
      });
    }

    if (selectedAsset.price) {
      setAssetPrice(weiToEther(selectedAsset.price.unit));
    }

    if (selectedAsset.attrributes && selectedAsset.attrributes.length) {
      selectedAsset.attrributes.forEach((attribute:any) => {
        switch (attribute.key) {
          case 'Score':
            setScore(attribute.value);
            break;
          case 'Country':
            setAgainst(attribute.value);
            break;
          case 'Century':
            setCentury(attribute.value);
            break;
          case 'Edition':
            setDatePlayed(attribute.value);
            break;
          default:
            break;
        }
      });
    }
  }

  function fetchAllAssetsData() {
    const userInfo = clientStorage.get(StorageKey.USER);

    return gql(`
      mintassetPerMinter (minter: "${userInfo.id}") {
        tokenid
        assetname
        description
        minter
        category
        locationUrl
        attrributes
        price
        status
        creationDate
      }
    `)
      .then((data:any) => data && data.mintassetPerMinter as any)
      .then((result:any) => {
        console.info("mintassetPercatalogPerMinter");
        console.info(result);
        setAllAssets(result);
        setAssetsToRender(result
          .filter((asset:any) => asset.status === category)
          .sort((a:any, b:any) => (new Date(b.creationDate)).getTime() - (new Date(a.creationDate)).getTime())
        );
      })
      .catch((error:any) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }

  function handleMomentCheckboxChange(event:any) {
    setIsMoment(event.target.checked);
  };

  function handleChange(event:any) {
    setSelectedCategory(event.target.value);
  };

  function updateAsset(event:any, status?: string) {
    event.preventDefault();
    setLoading(true);

    let categories = [];
    let attributes:{[key:string]: any} = {};

    categories.push(categoryMpping[selectedCategory]);

    if (isMoment) {
      categories.push(categoryMpping.moment);
    }

    if (validateAssetData()) {

      if (isMoment) {
        attributes.score = Number(score);
        attributes.century = Number(century);
        attributes.against = against;
        attributes.date = datePlayed;
      }

      gql(`
        mutation {
          nftassetControllerUpdateById(
            id: "${selectedAsset.tokenid}",
            nftAssetPartialInput:{
              category: [${categories.map((category:any) => (
                `{
                  name:"${category.name}",
                  parent:"${category.parent}",
                  category:"${category.category}"
                }`
              ))}],
              status: "${status ? status : selectedAsset.status}",
              price: {
                unit: ${Number(etherToWei(assetPrice))},
                currency: "${WEI_CURRENCY}",
                ratio: ${WEI_RATIO},
              }
              attrributes: ${isMoment ?
                `[
                {
                  key: "Score",
                  value: "${Number(score)}"
                },
                {
                  key: "Country",
                  value: "${against.trim()}"
                },
                {
                  key: "Century",
                  value: ${Number(century)}
                },
                {
                  key: "Edition",
                  value: "${moment(datePlayed).format('MMM DD, YYYY')}"
                },
              ]` : `[]`}
            }
          )
        }
      `)
        .then((data:any) => data && data.nftassetControllerUpdateById as any)
        .then((result:any) => {
          console.info("nftassetControllerUpdateById");
          console.info(result);
        })
        .catch((error:any) => {
          console.error(error);
        })
        .finally(() => {
          setDialogOpen(false);
          setLoading(false);
          clearFormData();
        });
    } else {
      setLoading(false);
    }
  };

  function mintAsset(asset:any) {
    const userInfo = clientStorage.get(StorageKey.USER);

    setLoading(true);

    gql(`
      mutation onlymint{
        blockchainControllerLazyMintNFT(mintNftAssetInput:{
          userid: "${userInfo.id}"
          tokenid: "${asset.tokenid}"
          price:{
            unit: ${weiToEther(asset.price.unit)}
            currency: "${ETH_CURRENCY}"
            ratio: ${ETH_RATIO}
          }
        })
      }
    `)
      .then((data:any) => data && data.blockchainControllerLazyMintNFT as any)
      .then((result:any) => {
        console.info("blockchainControllerLazyMintNFT");
        console.info(result);
        if(result.success) {
          showToast('Asset Minted SuccessFully', ToastType.SUCCESS);

          fetchAllAssetsData();
          setTimeout(() => {
            history.push('/all-assets/1');
          }, 1000);

        } else {
          showToast('Failed!', ToastType.ERROR);
        }
      })
      .catch((error:any) => {
        console.error(error);
        showToast('Failed!', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function validateAssetData() {
    let valid = true;
    if (parseFloat(assetPrice) === 0) {
      valid = false;
      setAssetPriceError('Price cannot be 0');
    }

    if (isMoment) {
      if (!!scoreError) {
        valid = false;
      } else {
        if (!score) {
          valid = false;
          setScoreError('Please enter Score value');
        }
        if (score.length > 0 && Number(score) === 0) {
          valid = false;
          setScoreError('Please enter value greater than 0');
        }
        if (Math.sign(Number(score)) === -1) {
          valid = false;
          setScoreError('Score can not be negative');
        }
      }

      if (!!centuryError) {
        valid = false;
      } else {
        if (!century) {
          valid = false;
          setCenturyError('Please enter Century # value');
        }
        if (century.length > 0 && Number(century) === 0) {
          valid = false;
          setCenturyError('Please enter value greater than 0');
        }
        if (Math.sign(Number(century)) === -1) {
          valid = false;
          setCenturyError('Century # can not be negative');
        }
      }

      if (!!againstError) {
        valid = false;
      } else {
        if (!against) {
          valid = false;
          setAgainstError('Please enter Opposite Team value');
        }
      }

      if (!!dateError) {
        valid = false;
      } else {
        if (!datePlayed) {
          valid = false;
          setDateError('Please enter Date');
        }
      }

      if (!isMoment) {
        // valid = false;
        // setCenturyError('This field is reuired');
      } else {
        const validScore = handleAssetInputBlur(score, 'score');
        const validAgainst = handleAssetInputBlur(against, 'against');
        const validCentury = handleAssetInputBlur(century, 'century');
        const validDate = handleAssetInputBlur(datePlayed, 'date');

        if(!validScore || !validAgainst || !validCentury || !validDate) {
          valid = false;
        }
      }
    }

    return valid;
  }

  function clearFormData() {
    setIsMoment(false);
    setSelectedCategory(RARITY.DIAMOND);
    setIsMoment(false);
    setScore('');
    setCentury('');
    setDatePlayed('');
    setAgainst('');
    setAssetPriceError('');
    setAgainstError('');
  }

  function handleAssetInputBlur (attributeValue:string, attributeType:string) {
    let valid = true;

    switch (attributeType) {
      case 'score':
        if (!attributeValue) {
          valid = false;
          setScoreError('Please enter Score value');
        }
        if (attributeValue.length > 0 && Number(attributeValue) === 0) {
          valid = false;
          setScoreError('Please enter value greater than 0');
        }
        if (Math.sign(Number(attributeValue)) === -1) {
          valid = false;
          setScoreError('Score can not be negative');
        }
        break;
      case 'century':
        if (!attributeValue) {
          valid = false;
          setCenturyError('Please enter Century # value');
        }
        if (attributeValue.length > 0 && Number(attributeValue) === 0) {
          valid = false;
          setCenturyError('Please enter value greater than 0');
        }
        if (Math.sign(Number(attributeValue)) === -1) {
          valid = false;
          setCenturyError('Century # can not be negative');
        }
        break;
      case 'against':
        if (!attributeValue || !attributeValue.trim()) {
          valid = false;
          setAgainstError('Please enter Opposite Team value');
        }
        break;
      case 'date':
        if (!attributeValue) {
          valid = false;
          setDateError('Please enter Date');
        }
        break;
      default:
        break;
    }
    return valid;
  }

  function showToast (message:string, toastType:ToastType = ToastType.SUCCESS) {
    setToastMessage(message)
    setToastVisible(true);
    setToastType(toastType);
  }

  return (
    <>
      <div className={classes.allAssets}>
        <Loader inProgress={loading} />
        {(allAssets && !allAssets.length) && (
          <div className={classes.noData}>
            No asset minted yet
          </div>
        )}

        {!!(allAssets && allAssets.length) && (
          <>
            <AllAssetTabBar
              className={classes.allAssetTabBar}
              selectedTab={Number(selectedTab)}
              setSelectedTab={(tab:number) => setSelectedTab(tab)}
              totalAsset={allAssets}
            />

          <div className={classes.assetSection}>
            <div className={classes.cardContainer}>
              {(assetsToRender && !assetsToRender.length) && (
                <div className={classes.noData}>
                  No asset available
                </div>
              )}
              {assetsToRender && !!assetsToRender.length && (
                (assetsToRender
                  .map((asset:any) => (
                    <div className={classes.card}>
                      <img
                        className={classes.assetImage}
                        src={asset.locationUrl}
                        alt={asset.assetname}
                      />
                      <div className={classes.assetInfo}>
                        <span className={classes.assetName}>{asset.assetname}</span>
                        {asset.price && (
                          <div className={classes.assetPrice}>{weiToEther(asset.price.unit)} {ETH_CURRENCY}</div>
                        )}
                      </div>
                      <div className={classes.separator}></div>
                      <div className={classes.actions}>
                        {
                          category !== TabType.sold &&
                          <Button
                          className={classes.btn}
                          disabled={category === TabType.sold}
                          primary
                          onClick={() => {
                            setSelectedAsset(asset);
                            category === TabType.created ? mintAsset(asset) : setDialogOpen(true);
                          }}
                        >

                          {category === TabType.created ? 'Mint' : 'Edit'}
                        </Button>
                        }

                      </div>
                    </div>
                  ))
                )
              )}
            </div>
          </div>
          </>
        )}
      </div>

      <Dialog
        className={classes.updateAssetModal}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={selectedAsset && selectedAsset.assetname}
      >
        <Loader inProgress={loading || !selectedAsset} />
        {!!selectedAsset && (
          <div className={classes.modalContent}>
            <div className={classes.leftContainer}>
              <img
                className={classes.assetImage}
                src={selectedAsset.locationUrl}
                alt={selectedAsset.assetname}
              />
              <div className={classes.assetInfo}>
                <span className={classes.assetName}>{selectedAsset.assetname}</span>
                {selectedAsset.price && (
                  <div className={classes.assetPrice}>{weiToEther(selectedAsset.price.unit)} {ETH_CURRENCY}</div>
                )}
              </div>
              <div className={classes.separator}></div>
              <div className={classes.actions}>
                <Button
                  className={clsx(classes.publishButton, (selectedAsset.status === TabType.pending) && classes.disabledButton)}
                  onClick={updateAsset}
                  disabled={selectedAsset.status === TabType.pending}
                >
                  Save
                </Button>
                  <div style={{marginBottom:"10px"}}> -OR-</div>
                <Button
                  className={clsx(classes.publishButton, (selectedAsset.status === TabType.pending) && classes.disabledButton)}
                  onClick={(event) => updateAsset(event, category === TabType.minted ? 'Listed': 'Minted')}
                  disabled={selectedAsset.status === TabType.pending}
                >
                  {category === TabType.minted ? 'Save & List' : 'Save & Unlist'}
                </Button>
              </div>
            </div>
            <div className={classes.rightContainer}>
              <div className={classes.separator}></div>
              <div className={classes.textContent}>
                <div style={{display:"flex",flexDirection:"column"}}>

                <span className={classes.rarityLabel}>Description&nbsp;</span>
                <span style={{marginTop:"5px"}}>  {selectedAsset.description} </span>
                </div>
              </div>

              <div className={classes.separator}></div>
              <div className={classes.categoryContainer}>
                <FormLabel className={classes.rarityLabel} required>Rarity</FormLabel>

                <FormHelperText>Select under which category your asset should be displayed</FormHelperText>
                <div className={classes.items}>
                  <div className={classes.radioContainer}>
                    <FormControlLabel
                      className={classes.radioLabel}
                      control={
                        <Radio
                          className={classes.checkbox}
                          checked={selectedCategory === 'diamond'}
                          name='Diamond'
                          color='primary'
                          value='diamond'
                          onChange={handleChange}
                        />
                      }
                      label='Diamond'
                    />
                  </div>

                  <div className={classes.radioContainer}>
                    <FormControlLabel
                      className={classes.radioLabel}
                      control={
                        <Radio
                          className={classes.checkbox}
                          checked={selectedCategory === 'platinum'}
                          name='Platinum'
                          color='primary'
                          value='platinum'
                          onChange={handleChange}
                        />
                      }
                      label='Platinum'
                    />
                  </div>

                  <div className={classes.radioContainer}>
                    <FormControlLabel
                      className={classes.radioLabel}
                      control={
                        <Radio
                          className={classes.checkbox}
                          checked={selectedCategory === 'gold'}
                          value='gold'
                          name='Gold'
                          color='primary'
                          onChange={handleChange}
                          />
                      }
                      label='Gold'
                    />
                  </div>
                </div>

                <FormHelperText>
                  <Checkbox
                    className={clsx(classes.checkbox, classes.galleryCheckbox)}
                    checked
                    onChange={() => {}}
                    name='gallery'
                    color='primary'
                    disabled
                  />&nbsp;Gallery, above selected will be moved inside Gallery
                </FormHelperText>
              </div>

              <div className={classes.separator}></div>
              <div className={classes.categoryContainer}>
                <div className={classes.items}>
                  <div className={classes.checkboxContainer}>
                    <FormControlLabel
                      className={classes.checkboxLabel}
                      control={
                        <Checkbox
                          className={classes.checkbox}
                          checked={isMoment}
                          onChange={handleMomentCheckboxChange}
                          name='Century'
                          color='primary'
                        />
                      }
                      label='Do you want to add Century Metadata?'
                    />
                    <FormHelperText>Select this if you want to display asset under Moment</FormHelperText>
                  </div>
                </div>
                {isMoment && (
                  <>
                    <div className={classes.inputContainer}>
                      <FormLabel required>Score</FormLabel>
                      <InputNumber
                        className={classes.input}
                        placeholder='Score'
                        value={score}
                        error={scoreError}
                        onChange={(value:string) => {
                          if (value.length > 0) {
                            setScoreError('');
                          }
                          setScore(value)
                        }}
                        onBlur={(value:string) => handleAssetInputBlur(value, 'score')}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      <FormLabel required>Century #</FormLabel>
                      <InputNumber
                        className={classes.input}
                        placeholder='Century'
                        value={century}
                        error={centuryError}
                        onChange={(value:string) => {
                          if (value.length > 0) {
                            setCenturyError('');
                          }
                          setCentury(value);
                        }}
                        onBlur={(value:string) => handleAssetInputBlur(value, 'century')}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      <FormLabel required>Opposite Team</FormLabel>
                      <Input
                        className={classes.input}
                        placeholder='Opposite Team'
                        value={against}
                        error={againstError}
                        onChange={(value:string) => {
                          if (value.length > 0) {
                            setAgainstError('');
                          }
                          setAgainst(value);
                        }}
                        onBlur={(value:string) => handleAssetInputBlur(value, 'against')}
                      />
                    </div>
                    <div className={classes.inputContainer}>
                      <FormLabel required>Date Played</FormLabel>
                      <Input
                        type='date'
                        className={classes.input}
                        placeholder='Date'
                        value={moment(datePlayed).format('YYYY-MM-DD')}
                        error={dateError}
                        onChange={(value:string) => {
                          setDatePlayed(value);
                          if (value.length) {
                            setDateError('');
                          }
                        }}
                        onBlur={(value:string) => handleAssetInputBlur(value, 'date')}
                      />
                    </div>
                  </>
                )}
              </div>

              {selectedAsset.price && (
                <>
                  <div className={classes.separator}></div>
                  <div className={classes.priceContainer}>
                    <FormLabel className={classes.rarityLabel} required>Price</FormLabel>
                    <div className={classes.priceDiv}>
                      <InputNumber
                        className={classes.priceInput}
                        inputFieldClassName={classes.inputField}
                        label='Price'
                        value={assetPrice}
                        error={assetPriceError}
                        endAdornment={{label: ETH_CURRENCY, position: 'end'}}
                        onChange={(value:string) => {
                            if(!value.trim() || parseFloat(value.trim()) >= 0) {
                              setAssetPrice(value.trim());
                              assetPriceError && setAssetPriceError('')
                            } else {
                              setAssetPriceError('Price cannot be negative')
                            }
                        }}
                        min={0}
                        step='any'
                      />
                    </div>
                  </div>
                </>
              )}
              {(selectedAsset.status === TabType.pending) && (
                <div className={classes.pendingMsg}>
                  Asset is in Pending state! Please try after sometime.
                </div>
              )}
            </div>
          </div>
        )}
      </Dialog>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user
  }
};

export default connect(
  mapStateToProps,
)(AllAssets);