import { Dispatch,  } from 'redux';
import { setUser } from '../actions/userAction';
import gql from '../../services/gql';
import * as clientStorage from '../../services/client-storage';
import { StorageKey } from '../../services/client-storage';
import User from '../../types/User';

export function fetchUser () {
  return (dispatch:Dispatch, getState:any) => {
    return gql(`
        usersMe {
          email
          id
          name
        }
    `)
      .then((data:any) => data && data.usersMe as User)
      .then((user:User) => {
        clientStorage.save(StorageKey.USER, user);
        dispatch(setUser(user));
      })
      .catch(console.error);
  };
}