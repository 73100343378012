//@ts-nocheck
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Loader from '../atoms/Loader';
import Input from '../atoms/Input';
import InputNumber from '../atoms/InputNumber';
import Button from '../atoms/Button';
import FileDropzone from '../atoms/FileDropzone';
import { FormHelperText } from '@material-ui/core';
import Toast, { ToastType } from '../atoms/Toast';
import { RARITY, RARITY_TITLE, categoryMpping } from './AllAssets';
import { uploadImage } from '../services/nft-storage-api';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { weiToEther, etherToWei } from '../services/helper';
import { WEI_CURRENCY, WEI_RATIO } from '../services/constants';
import { FONT_NUNITO } from '../styles/fonts';
import { COLOR_PRIMARY, COLOR_RED_ERROR, COLOR_TEXT, COLOR_LIGHT_GRAY, COLOR_WHITE, COLOR_GREEN } from '../styles/colors';
import User from '../types/User';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface AddAssetProps {
  user: User;
}

const useStyles = makeStyles(theme => ({
  addAssetWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  left: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',

    '@media (max-width: 991px)': {
      display: 'none',
    }
  },
  previewCard: {
    width: '300px',
  },
  cardRoot: {
    boxShadow: `5px 5px 5px 5px ${COLOR_LIGHT_GRAY}`,
    margin: '50px auto',
    maxWidth: 345,
    padding: theme.spacing(3, 4),

    '& .MuiCardHeader-root': {
      padding: theme.spacing(0, 0, 1, 0),
    },
    '& .MuiCardHeader-title': {
      fontWeight: 700,
    },

    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 0, 1, 0),
    }
  },
  cardPreviewContent: {
    color: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(20),
  },
  assetImage: {
    width: '100%',
    height:'300px',
    borderRadius: '8px',
    backgroundSize: 'contain',
  },
  cardTextColor: {
    color: COLOR_GREEN,
  },
  cardMedia: {
    height: 0,
    paddingTop: '56.25%',
  },
  addAsset: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 20),
    width: '60%',

    '@media (max-width: 1025px)': {
      padding: theme.spacing(0, 0),
    },
    '@media (max-width: 991px)': {
      width: '100%',
    },
  },
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    marginBlock: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  addAssetForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  label: {
    fontWeight: 600,
    display: 'inline-block',
  },
  centuryLabel: {
    marginRight: 0,
  },
  required: {
    '&:after': {
      content:" *",
      color: COLOR_RED_ERROR,
    }
  },
  input: {
    marginTop: theme.spacing(1),
    borderRadius: '4px',
  },
  checkbox: {
    color: COLOR_PRIMARY,

    '& $checkIconWrapper': {
      background: COLOR_PRIMARY,
      borderColor: COLOR_PRIMARY,
    }
  },
  items: {
    display: 'flex',
  },
  radioContainer: {
    width: '33%',
  },

  submitWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(3),

    '@media (max-width: 991px)': {
      flexDirection: 'column',
    },
  },
  submitButton: {
    backgroundColor: COLOR_WHITE,
    borderRadius: '25px',
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(2, 6),
    width: 'auto',
  },
  clearButton: {
    alignItems: 'center',
    backgroundColor: COLOR_WHITE,
    borderRadius: '25px',
    color: COLOR_TEXT,
    display: 'flex',
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),
    justifyContent: 'center',
    marginLeft: '30px',
    padding: theme.spacing(2, 3),
    width: 'auto',

    '@media (max-width: 991px)': {
      marginLeft: 0,
      marginTop: '20px',
    },
  },
  hidden: {
    display: 'none'
  },
  error: {
    color: COLOR_RED_ERROR,
    fontSize: '12px',
    marginTop: '16px',
  },
  assetLabel: {
    color: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
  },
  labelHelper: {
    margin: '10px 0',
  }
}), { name: AddAsset.name });

function AddAsset (props:AddAssetProps) {
  const classes = useStyles();
  const { user } = props;
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assetName, setAssetName] = useState<string>('');
  const [selectedFile, setSelectedfile] = useState<any>();
  const [previewCardFile, setpreviewCardFile] = useState<any>();
  const [description, setDescription] = useState<string>('');
  const [twinValue, setTwinValue] = useState<string>('');
  const [assetPrice, setAssetPrice] = useState<string>('');
  const [isDigitalTwin, setIsDigitalTwin] = useState<boolean>(false);
  const [isCentury, setIsCentury] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<string>(RARITY.DIAMOND);
  const [score, setScore] = useState<string>('');
  const [century, setCentury] = useState<string>('');
  const [against, setAgainst] = useState<string>('');
  const [datePlayed, setDatePlayed] = useState<string>('');

  const [assetNameError, setAssetNameError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [assetPriceError, setAssetPriceError] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageError, setImageError] = useState<string>('');
  const [centuryError, setCenturyError] = useState<string>('');
  const [centuryTextError, setCenturyTextError] = useState<string>('');
  const [scoreError, setScoreError] = useState<string>('');
  const [againstError, setAgainstError] = useState<string>('');
  const [dateError, setDateError] = useState<string>('');

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);

  useEffect(() => {
    if (assetName) {
      setAssetNameError('');
    }
  }, [assetName]);

  useEffect(() => {
    if (description) {
      setDescriptionError('');
    }
  }, [description]);

  useEffect(() => {
    if (assetPrice) {
      setAssetPriceError('');
    }
  }, [assetPrice]);

  useEffect(() => {
    if (selectedFile) {
      setImageError('');
    }
  }, [selectedFile]);

  function uploadFile(file:any) {
    if (file) {
      setSelectedfile(file);
      setpreviewCardFile(URL.createObjectURL(file));
      uploadImage(file)
      .then((data:any) => {
        setImageUrl(`https://${data.cid}.ipfs.dweb.link/`);
      })
      .catch(console.error);
    }
  }

  function handleCheckboxChange(event:any, key:string) {
    switch (key) {
      case 'digital-twin':
        setIsDigitalTwin(event.target.checked);
        break;
      case 'century':
        setIsCentury(event.target.checked);
        break;

      default:
        break;
    }
  };

  function handleAssetNameBlur(value:string) {
    if (!value) {
      setAssetNameError('This field is required.');
    }
  }

  function handleAssetDescBlur(value:string) {
    if (!value) {
      setDescriptionError('This field is required.');
    }
  }

  function handleSubmit(event:any) {
    event.preventDefault();

    setIsLoading(true);
    if (validateAsset(true)) {
      const userInfo = clientStorage.get(StorageKey.USER);
      let categories:any = [];

      if (isCentury) {
        categories.push(categoryMpping.moment);
      }

      gql(`
        mutation createassetWithoutMint{
          nftassetControllerCreate(createNftAssetInput:{
            assetname: "${assetName.trim()}"
            description: "${description.trim()}"
            locationUrl: "${imageUrl}"
            status: "Created"
            minter: "${userInfo.id}"
            digitalTwin: "${twinValue}"
            price: {
              currency: "${WEI_CURRENCY}",
              ratio: ${WEI_RATIO},
              unit: ${Number(etherToWei(assetPrice))},
            }
            category: ${categories.length ? categories.map((category:any) => (
              `[{
                name:"${category.name}",
                parent:"${category.parent}",
                category:"${category.category}"
              }]`
            )): `[]`},
            attrributes: ${isCentury ? `[
              {
                key: "Score",
                value: "${Number(score)}"
              },
              {
                key: "Country",
                value: "${against.trim()}"
              },
              {
                key: "Century",
                value: ${Number(century)}
              },
              {
                key: "Edition",
                value: "${moment(datePlayed).format('MMM DD, YYYY')}"
              }
            ]`: `[]`}
          }
        ){
            assetname
            tokenid
            minter
            status
          }
        }
      `)
        .then((data:any) => data && data.nftassetControllerCreate as any)
        .then((result:any) => {
          if (result) {
            showToast('Asset Added SuccessFully', ToastType.SUCCESS);

            setTimeout(() => {
              history.push('/all-assets');
            }, 1000);
          } else {
            showToast('Failed!', ToastType.ERROR);
          }
        })
        .catch((error:any) => {
          console.error(error);
          showToast('Failed!', ToastType.ERROR);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }

  function validateAsset(submit:boolean) {
    let valid = true;

    if (!assetName || !assetName.trim()) {
      valid = false;
      if(submit) {
        setAssetNameError('Please enter asset name');
      }
    }

    if (!description || !description.trim()) {
      valid = false;
      if(submit) {
        setDescriptionError('Please enter asset description');
      }
    }

    if (!selectedFile) {
      valid = false;
      if(submit) {
        setImageError('Please select an Image');
      }
    }

    if(!assetPrice || parseFloat(assetPrice) == 0) {
      valid = false;
      if(submit) {
        setAssetPriceError('Please enter price and price cannot be 0');
      }
    }

    if (!isCentury) {
      // valid = false;
      // if(submit) {
      //   setCenturyError('This field is reuired');
      // }
    } else {
      const validScore = handleAssetInputBlur(score, 'score', submit);
      const validAgainst = handleAssetInputBlur(against, 'against', submit);
      const validCentury = handleAssetInputBlur(century, 'century', submit);
      const validDate = handleAssetInputBlur(datePlayed, 'date', submit);

      if(!validScore || !validAgainst || !validCentury || !validDate) {
        valid = false;
      }
    }

    return valid;
  }

  function handleOnDelete(file:any) {
    if (file) {
      setSelectedfile(undefined);
    }
  }

  function showToast (message:string, toastType:ToastType = ToastType.SUCCESS) {
    setToastMessage(message)
    setToastVisible(true);
    setToastType(toastType);
  }

  function handleAssetInputBlur (attributeValue:string, attributeType:string, attributeCheck:boolean) {
    let valid = true;
    switch (attributeType) {
      case 'score':
        if (!attributeValue) {
          valid = false;
          if(attributeCheck) {
            setScoreError('Please enter Score value');
          }
        }
        if (attributeValue.length > 0 && Number(attributeValue) === 0) {
          valid = false;
          if(attributeCheck) {
            setScoreError('Please enter value greater than 0');
          }
        }
        if (Math.sign(Number(attributeValue)) === -1) {
          valid = false;
          if(attributeCheck) {
            setScoreError('Score can not be negative');
          }
        }
        break;
      case 'century':
        if (!attributeValue) {
          valid = false;
          if(attributeCheck) {
            setCenturyTextError('Please enter Century # value');
          }
        }
        if (attributeValue.length > 0 && Number(attributeValue) === 0) {
          valid = false;
          if(attributeCheck) {
            setCenturyTextError('Please enter value greater than 0');
          }
        }
        if (Math.sign(Number(attributeValue)) === -1) {
          valid = false;
          if(attributeCheck) {
            setCenturyTextError('Century # can not be negative');
          }
        }
        break;
      case 'against':
        if (!attributeValue || !attributeValue.trim()) {
          valid = false;
          if(attributeCheck) {
            setAgainstError('Please enter Opposite Team value');
          }
        }
        break;
      case 'date':
        if (!attributeValue) {
          valid = false;
          if(attributeCheck) {
            setDateError('Please enter Date');
          }
        }
        break;
      default:
        break;
    }
    return valid;
  }

  function cancelData() {
    setSelectedfile('');
    setpreviewCardFile('');
    setAssetName('');
    setDescription('');
    setIsCentury(true);
    setScore('');
    setCentury('');
    setAgainst('');
    setDatePlayed('');
    setAssetPrice('');
  }

  return (
    <>
      <div className={classes.title}>Create New Asset</div>
        <div className={classes.addAssetWrapper}>
          <div className={classes.left}>
            <div className={classes.previewCard}>
              <Card className={classes.cardRoot}>
                <CardHeader
                  title="Preview"
                />
                <CardMedia
                  className={classes.assetImage}
                  image={previewCardFile}
                />
                <CardContent>
                  <div className={classes.cardPreviewContent}>Name: <span className={classes.cardTextColor}>{assetName}</span></div>
                  <div className={classes.cardPreviewContent}>Price: <span className={classes.cardTextColor}>{assetPrice} ETH</span></div>
                </CardContent>
              </Card>
            </div>
          </div>

        <div className={classes.addAsset}>
          <form
          className={classes.addAssetForm}
          onSubmit={handleSubmit}
          >
          <Loader inProgress={isLoading} />

          <div className={classes.inputContainer}>
            <FormLabel className={classes.assetLabel}>Upload file</FormLabel>
            <FormHelperText className={classes.labelHelper}>Drag or choose your file to upload</FormHelperText>
            <FileDropzone
              selectedFile={selectedFile}
              onChange={(file:any) => uploadFile(file)}
              handleOnDelete={(file:any) => handleOnDelete(file)}
            />
            {!!imageError && (
              <div className={classes.error}>{imageError}</div>
            )}
          </div>

          <div className={classes.inputContainer}>
            <FormLabel required>Name</FormLabel>
            <Input
              className={classes.input}
              label='Name'
              value={assetName}
              error={assetNameError}
              onChange={(value:string) => setAssetName(value)}
              onBlur={(value:string) => handleAssetNameBlur(value)}
            />
          </div>

          <div className={classes.inputContainer}>
            <FormLabel required>Description</FormLabel>
            <Input
              className={classes.input}
              label='Description'
              value={description}
              error={descriptionError}
              multiLine
              onChange={(value:string) => setDescription(value)}
              onBlur={(value:string) => handleAssetDescBlur(value)}
            />
          </div>

          <div className={classes.inputContainer}>
            <FormLabel>
              <FormControlLabel
                className={classes.centuryLabel}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={isCentury}
                    onChange={(event:any) => handleCheckboxChange(event, 'century')}
                    name='Century'
                    color='primary'
                  />
                }
                label='Do you want to add Century Metadata?'
              />
            </FormLabel>
          </div>

          {isCentury && (
            <>
              <div className={classes.inputContainer}>
                <FormLabel required>Score</FormLabel>
                <InputNumber

                  className={classes.input}
                  placeholder='Score'
                  value={score}
                  error={scoreError}
                  onChange={(value:string) => {
                    if (value.length > 0) {
                      setScoreError('');
                    }
                    setScore(value)
                  }}
                  onBlur={(value:string) => handleAssetInputBlur(value, 'score')}
                  min={1}
                />
              </div>
              <div className={classes.inputContainer}>
                <FormLabel required>Century #</FormLabel>
                <InputNumber
                  min={1}
                  className={classes.input}
                  placeholder='Century'
                  value={century}
                  error={centuryTextError}
                  onChange={(value:string) => {
                    if (value.length > 0) {
                      setCenturyTextError('');
                    }
                    setCentury(value);
                  }}
                  onBlur={(value:string) => handleAssetInputBlur(value, 'century')}
                />
              </div>
              <div className={classes.inputContainer}>
                <FormLabel required>Opposite Team</FormLabel>
                <Input
                  className={classes.input}
                  placeholder='Opposite Team'
                  value={against}
                  error={againstError}
                  onChange={(value:string) => {
                    if (value.length > 0) {
                      setAgainstError('');
                    }
                    setAgainst(value);
                  }}
                  onBlur={(value:string) => handleAssetInputBlur(value, 'against')}
                />
              </div>
              <div className={classes.inputContainer}>
                <FormLabel required>Date Played</FormLabel>
                <Input
                  type='date'
                  className={classes.input}
                  placeholder='Date'
                  value={datePlayed}
                  error={dateError}
                  onChange={(value:string) => setDatePlayed(value)}
                  onBlur={(value:string) => handleAssetInputBlur(value, 'date')}
                />
              </div>
            </>
          )}

          <div className={classes.inputContainer}>
            <FormLabel required>Asset Price</FormLabel>
            <InputNumber
              className={classes.input}
              label='Asset Price'
              value={assetPrice}
              error={assetPriceError}
              onChange={(value:string) => { if(!value.trim() || parseFloat(value.trim()) >= 0) {setAssetPrice(value.trim());}} }
              endAdornment={{label: 'ETH', position: 'end'}}
              min={0}
              step='any'
            />
          </div>

          <FormGroup row>
            <FormControlLabel
              className={classes.hidden}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={isDigitalTwin}
                  onChange={(event:any) => handleCheckboxChange(event, 'digital-twin')}
                  name='Digital Twin'
                  color='primary'
                />
              }
              label='Digital Twin'
            />
          </FormGroup>
          {isDigitalTwin && (
            <div className={classes.inputContainer}>
              <div className={classes.label}>Twin Value</div>
              <Input
                className={classes.input}
                label='Twin Value'
                value={twinValue}
                onChange={(value:string) => setTwinValue(value)}
              />
            </div>
          )}

          <div className={classes.submitWrapper}>
            { (validateAsset(false) && !isLoading) ?
              <Button
                primary
                className={classes.submitButton}
                type='submit'
              >
                Submit
             </Button>
            :  <Button
                primary
                className={classes.submitButton}
                disabled={true}
                type='submit'
              >
              Submit
              </Button>
          }

            <Button
                primary
                className={classes.clearButton}
                disabled={isLoading}
                type='button'
                onClick={cancelData}
              >
                <HighlightOffIcon />  Clear all
              </Button>
          </div>
        </form>
        </div>

        <Toast
          visible={toastVisible}
          type={toastType}
          onHide={() => setToastVisible(false)}
        >
          {toastMessage}
        </Toast>
      </div>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user
  }
};

export default connect(
  mapStateToProps,
)(AddAsset);