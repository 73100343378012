import Page from './Page';
import PageTemplate from '../templates/PageTemplete';
import AllAssets from '../organisms/AllAssets';

export default function DashboardPage() {
  return (
    <Page>
      <PageTemplate>
        <AllAssets />
      </PageTemplate>
    </Page>
  );
}