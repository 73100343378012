import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MaterialModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '../atoms/Button';
// import CloseButton from '../atoms/CloseButton';
import { COLOR_BACKGROUND_LIGHT, COLOR_TEXT, COLOR_WHITE, COLOR_DARK_GRAY, COLOR_PRIMARY } from '../styles/colors';
import { FONT_NUNITO } from '../styles/fonts';

interface DialogProps {
  className?: string;
  contentClassName?: string;
  open: boolean;
  title: string;
  text?: string;
  actions?: DialogAction[];
  children?: any;
  onClose: () => void;
}

interface DialogAction {
  name: string;
  primary?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    position:'absolute',
    top:'10%',
    left:'10%',
    overflow:'scroll',
    height:'100%',
    display:'block'
  },
  backdrop: {
    background: 'rgba(0, 0, 0, 0.7)',
  },
  paper: {
    // width: '96%',
    maxWidth: '1100px',
    margin: theme.spacing(2.5, 'auto'),
    boxShadow: theme.shadows[5],
    outline: 'none',
    overflow: 'auto',
  },

  header: {
    padding: theme.spacing(5,4),
    display: 'flex',
    flexDirection: 'column',
    background: COLOR_BACKGROUND_LIGHT,
    borderRadius: '8px 8px 0 0',
    position: 'relative',
  },
  headerTitle: {
    margin: 0,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.3,
    fontWeight: 600,
    textAlign: 'left',
    letterSpacing: 0,
    color: COLOR_TEXT,
  },

  contentSection: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    background: COLOR_WHITE,
    borderRadius: '0 0 8px 8px',
  },

  text: {
    margin: 0,
    fontFamily: FONT_NUNITO,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.3,
    color: COLOR_TEXT,
    textAlign: 'center',
  },

  actions: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',

    '&:empty': {
      display: 'none',
    },
  },
  action: {
    width: '50%',
    maxWidth: '180px',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '24px',
    height: '24px',
    padding: 0,
    appearance: 'none',
    background: 'none',
    border: `1px solid transparent`,
    borderRadius: '50%',
    color: COLOR_DARK_GRAY,
    cursor: 'pointer',
    transition: 'color 0.3s, border-color 0.3s',
    boxSizing: 'border-box',

    '&:before': {
      content: '""',
      width: '2px',
      height: '20px',
      background: 'currentColor',
      position: 'absolute',
      top: '1px',
      left: '10px',
      transform: 'rotate(45deg)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '20px',
      background: 'currentColor',
      position: 'absolute',
      top: '1px',
      left: '10px',
      transform: 'rotate(-45deg)',
    },

    '&:hover': {
      color: COLOR_PRIMARY,
    },

    '&:focus': {
      outline: 0,
      borderColor: COLOR_DARK_GRAY,
    },
  },
}), { name: Dialog.name });

export default function Dialog (props: DialogProps) {
  const {
    className,
    contentClassName,
    open = false,
    title = '',
    text = '',
    actions = [],
    children,
    onClose,
  } = props;
  const classes = useStyles();

  return (
    <MaterialModal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={clsx(classes.dialog, className)}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: classes.backdrop
      }}
      onBackdropClick={onClose}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <h2
              id='transition-modal-title'
              className={classes.headerTitle}
            >
              {title}
            </h2>

            <button
              className={classes.closeButton}
              onClick={onClose}
            />
          </div>

          <div
            className={classes.contentSection}
            id='transition-modal-description'
          >
            {text && (
              <p className={classes.text}>{text}</p>
            )}

            {children}

            {actions && (
              <div className={classes.actions}>
                {actions.map((action, index) => (
                  <Button
                    key={index}
                    className={classes.action}
                    primary={action.primary}
                    onClick={action.onClick}
                    disabled={action.disabled}
                  >
                    {action.name}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </Fade>
    </MaterialModal>
  );
}
