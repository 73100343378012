import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';

import { API_URL } from './constants';

const GQL_URL = `${API_URL}/graphQL/graphql`;

export function logIn (email:string, password:string):Promise<Boolean> {
  const query = `mutation {
    userManagementControllerLogin(usersLoginInput:{
      email:"${email}",
      password:"${password}"
    }){
      token
    }
  }`;

  return fetch(`${GQL_URL}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: `${query}`}),
  })
    .then(res => {
      if (res.ok) return res.json();

      throw new Error('Login attempt failed. Please check your email and password, and try again.')
    })
    .then((data:any) => {
      const result = data.data;
      const errorMessage = data.errors && data.errors[0].message;

      return {
        ...result,
        errorMessage,
      };
    })
    .catch(console.error);
}

export function isLoggedIn () {
  return !!clientStorage.get(StorageKey.TOKEN);
}

export function saveUser (token:string) {
  clientStorage.save(StorageKey.TOKEN, token);
}

export function logout () {
  clientStorage.clearAll();
}

export function validatePassword (password:string):{ valid:boolean, error:string } {
  let valid = true;
  let error = '';

  // if (password.length < 6) {
  //   valid = false;
  //   error = 'The password must be at least 6 characters long.';
  // }

  // if (!(/[A-Z]/.test(password))) {
  //   valid = false;
  //   error = 'The password must contain at least one capital character.';
  // }

  // if (!(/[0-9]/.test(password))) {
  //   valid = false;
  //   error = 'The password must contain at least one digit.';
  // }

  return { valid, error };
}
