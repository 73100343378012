import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import clsx from 'clsx';
import { COLOR_TEXT, COLOR_PRIMARY } from '../styles/colors';

export const TabType:{[key:string]: string} = {
  created: 'Created',
  listed: 'Listed',
  sold: 'Sold',
  minted: 'Minted',
  pending: 'Pending',
};

export const TABS = [
  TabType.created,
  TabType.minted,
  TabType.listed,
  TabType.sold,
];

interface AllAssetTabBarProps {
  className?: string,
  selectedTab: number,
  setSelectedTab: (value:number) => void;
  totalAsset: any,
}

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
  },
  tabs: {
    color: COLOR_TEXT,
  },
  tab: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  selected: {
    borderBottom: `3px solid ${COLOR_PRIMARY}`
  }
}), { name: AllAssetTabBar.name });

export default function AllAssetTabBar(props:AllAssetTabBarProps) {
  const classes = useStyles();
  const history = useHistory();
  const {
    className,
    selectedTab,
    setSelectedTab,
    totalAsset,
  } = props;

  const handleChange = (event:any, newValue:number) => {
    setSelectedTab(newValue);
    history.push(`/all-assets/${newValue}`);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Tabs
        className={classes.tabs}
        classes={{
          indicator: classes.selected
        }}
        value={Number(selectedTab)}
        onChange={handleChange}
        centered
      >
        {TABS.map((tab:string) => (
          <Tab
            className={classes.tab}
            label={`${tab} (${totalAsset ? (totalAsset.filter((totalAsset:any) => totalAsset.status === tab)).length : '' }) `}
          />
        ))}
      </Tabs>
    </div>
  );
}