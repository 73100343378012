import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLOR_PRIMARY } from '../styles/colors';

interface LoaderProps {
  className?: string;
  inProgress: boolean;
  spinnerClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1000,
  },
  spinner: {
    color: COLOR_PRIMARY,
  },
}), { name: Loader.name });

export default function Loader (props: LoaderProps) {
  const {
    className,
    inProgress = false,
    spinnerClassName = '',
  } = props;
  const classes = useStyles();

  return (
    <Fade
      in={inProgress}
      style={{ transitionDelay: '0' }}
      unmountOnExit
    >
      <div className={clsx(classes.loader, className)}>
        <CircularProgress classes={{ root: clsx(classes.spinner, spinnerClassName) }} />
      </div>
    </Fade>
  );
}
