import User from '../../types/User';
import {
  SET_USER
} from '../actions/userAction';

export interface UserState extends User {}

export function getUserInitialState () {
  return INITIAL_STATE;
}

const INITIAL_STATE:UserState = {} as UserState;


export default function userReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    default:
      return state;
  }
};