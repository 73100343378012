import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import NavBar, { NAVBAR_HEIGHT } from '../molecules/Navbar';
import { fetchUser } from '../redux/dispatchers/user';
import { isLoggedIn } from '../services/user';
import { COLOR_WHITE } from '../styles/colors';
import { FONT_NUNITO } from '../styles/fonts';
import clsx from 'clsx';

interface PageProps {
  className?: string;
  containerClass?: string;
  children: any;
  fetchUser: () => void;
}

const useStyles = makeStyles(theme => ({
  page: {
    width: '100%',
    display: 'flex',
    background: COLOR_WHITE,
    fontFamily: FONT_NUNITO,
  },
  pageContainer: {
    flexGrow: 1,
    width: '100%',
    minHeight: '100vh',

    paddingTop: `${NAVBAR_HEIGHT}px`,
  },
  container: {
    width: `100vw`,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    boxSizing: 'border-box',
    transition: 'margin 0.3s, width 0.3s',
    padding: theme.spacing(2, 0),
  },
}), { name: Page.name });

function Page (props:PageProps) {
  const {
    className,
    containerClass,
    children,
    fetchUser,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (isLoggedIn()) {
      fetchUser();
    }
  }, []);

  return(
    <div className={classes.page}>

      <main className={classes.pageContainer}>

           {/* { isLoggedIn() ? <NavBar /> : ""
        } */}
        <NavBar />

        <Container
          className={clsx(classes.container, containerClass)}
          maxWidth={false}
        >
          {children}
        </Container>
      </main>
    </div>
  );
}

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchUser,
    },
    dispatch
  )
};

export default connect(
  null,
  mapDispatchToProps,
)(Page);
