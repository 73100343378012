import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';

import { API_URL } from './constants';

const GQL_URL = `${API_URL}/graphQL/graphql`;

export default function gql(query:string, data?:Object | Array<any>):Promise<any> {
  if (data) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(data), 300);
    });
  }

  const isMutation = query.trim().startsWith('mutation');

  return fetch(GQL_URL, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${clientStorage.get(StorageKey.TOKEN)}`,
    },
    body: JSON.stringify({ query: isMutation ? `${query}` : `query{ ${query} }` }),
  })
    .then(res => {
      if (res.ok) return res.json();

      throw new Error('Failed to get GQL response');
    })
    .then((data:any) => {
      const result = data.data;
      const errorMessage = data.errors && data.errors[0].message;

      return {
        ...result,
        errorMessage,
      };
    })
    .catch(console.error);
}
