import { ethers } from 'ethers';

export function weiToEther(weiAmount:string):string {
  try {
    return ethers.utils.formatEther(weiAmount.toString());
  }
  catch {
    return weiAmount.toString();
  }
}

export function etherToWei(ethAmount:string):string {
  let wei = ethers.utils.parseEther(ethAmount);
  return wei.toString();
}