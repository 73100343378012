export const COLOR_WINE = '#7b0041';
export const COLOR_LIGHT_WINE = '#ffeef7';
export const COLOR_WHITE = '#ffffff';
export const COLOR_TEXT = '#070a13';
export const COLOR_DARK_GRAY = '#777777';
export const COLOR_LIGHT_GRAY = '#d2d2d2';
export const COLOR_SHADOW = 'rgb(123 0 65 / 5%)';
export const COLOR_GREEN = '#15b05e';
export const COLOR_RED = '#b71313';
export const COLOR_RED_ERROR = '#f44336';
export const COLOR_BACKGROUND_LIGHT = '#f8f8f8';

export const COLOR_PRIMARY = '#002F52';
export const COLOR_SECONDARY = '#005699';
export const COLOR_LOGIN_BTN = '#002F52';
export const COLOR_LOGIN_BG = '#915F81';
export const COLOR_HOME_TEXT = '#847A90';
