import LoginForm from '../organisms/Login';
import StandAlonePage from './StandAlonePage';
import { makeStyles} from '@material-ui/core/styles';
import Image from '../assets/images/bg1.png';
import { FONT_NUNITO } from '../styles/fonts';

const useStyles = makeStyles(theme => ({
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    background: `url("${Image}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  title: {
    ...theme.typography.h1,
    fontSize: theme.typography.pxToRem(28),
    margin: 0,
  },
  input: {
    marginTop: theme.spacing(3),
  },

  submitWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  submitButton: {
    width: 'auto',
    padding: theme.spacing(2, 4),
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),
  },

  '@media (max-height: 800px)': {
    footer: {
      position: 'relative',
      bottom: 'auto',
      marginTop: theme.spacing(8),
    },
  },

  '@media (max-width: 400px)': {
    submitButton: {
      padding: theme.spacing(2, 3),
    },
    forgotPassword: {
      padding: 0,
    },
  },
}), { name: LoginPage.name });

export default function LoginPage() {
  const classes = useStyles();

  return (
    <StandAlonePage>
      <div className={classes.loginForm}>
        <LoginForm />
      </div>
    </StandAlonePage>
  );
}