import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { configureStore } from './redux/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  COLOR_TEXT,
  COLOR_SHADOW,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_RED_ERROR,
} from './styles/colors';
import { FONT_NUNITO } from './styles/fonts';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_SECONDARY,
    },
  },
  typography: {
    fontFamily: [
      'Nunito',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: `0 0 25px 0 ${COLOR_SHADOW}`,
      },
      rounded: {
        borderRadius: '5px',
        border: '1px solid #efefef',
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: COLOR_RED_ERROR,
        '&$error': {
          color: COLOR_RED_ERROR
        },
      }
    }
  },
});

theme.typography.h1 = {
  fontFamily: FONT_NUNITO,
  fontSize: '2rem',
  fontWeight: 600,
  lineHeight: 1.2,
  letterSpacing: 0,
  color: COLOR_TEXT,
};

theme.typography.h2 = {
  fontFamily: FONT_NUNITO,
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: 1.2,
  letterSpacing: 0,
  color: COLOR_TEXT,

  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
};
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={configureStore()}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
