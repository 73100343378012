import React from 'react';
import clsx from 'clsx';
import { useLocation, NavLink } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_LIGHT_WINE, COLOR_TEXT, COLOR_WINE } from '../styles/colors';

interface ActionProps {
  children: React.ReactNode;
  className?: string;
  selectedClassName?: string;
  href?: string;
  target?: string;
  disabled?: boolean;
  externalLink?: boolean;
  onClick?: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  action: {
    display: 'flex',
    alignItems: 'center',
    appearance: 'none',
    background: 'none',
    border: 'none',
    color: COLOR_TEXT,
    cursor: 'pointer',
    textDecoration: 'none',
    overflow: 'hidden',

    '&:hover': {
      textDecoration: 'underline',
      color: COLOR_WINE,
      backGroundColor: COLOR_LIGHT_WINE,
    },
  },
  disabled: {
    opacity: '0.6',
    cursor: 'not-allowed',

    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
}), { name: Action.name });

export default function Action (props: ActionProps) {
  const {
    children,
    className,
    selectedClassName,
    href = '',
    target,
    disabled = false,
    externalLink = false,
    onClick = () => {},
  } = props;
  const classes = useStyles();
  const location = useLocation();

  const Wrapper:any = href
    ? (externalLink ? 'a' : NavLink)
    : 'button';
  const wrapperProps = href
    ? (externalLink
        ? { href, target }
        : {
            to: href.includes('?') ? href : `${href}${location.search}`,
            activeClassName: selectedClassName,
            target,
          }
      )
    : {
        onClick: !disabled ? onClick : undefined,
      };

  return (
    <Wrapper
      className={clsx(
        classes.action,
        disabled && classes.disabled,
        className,
      )}
      {...wrapperProps}
    >
      {children}
    </Wrapper>
  );
}
