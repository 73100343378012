export function getQueryParameter (key:string):string {
  return (
    window.location.search
      .slice(1)
      .split('&')
      .filter(Boolean)
      .find(searchPair => searchPair.startsWith(`${key}=`))
    || ''
  ).replace(`${key}=`, '');
}
