import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Router from './routes/Router';

import './App.css';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontSize: 16,
  },
}), { name: App.name });

function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline/>

      <Router />
    </div>
  );
}

export default App;
