//@ts-nocheck
import React from "react"
import { makeStyles} from '@material-ui/core/styles';
import Action from '../atoms/Action';

import { COLOR_PRIMARY, COLOR_TEXT, COLOR_HOME_TEXT, COLOR_DARK_GRAY, COLOR_WHITE} from '../styles/colors';
import { FONT_NUNITO } from '../styles/fonts';
import bgLandingImage from '../assets/images/bgLanding.png';
import homeImage from '../assets/images/lara_logo_mint_app.png';
import vlinderLogo from "../assets/images/Vlinder_new_logo_small.png"
import { isLoggedIn } from '../services/user';
import {useHistory} from "react-router-dom"

const useStyles = makeStyles(theme => ({
  homeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '0 auto',
    padding: '25px',
    alignItems: 'center',
    background: `url("${bgLandingImage}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '@media (max-width: 991px)': {
      alignItems: 'flex-start',
    }
  },
  loginWrapper: {
    width: '50%',
    padding: '200px 100px',
    fontWeight: 600,
    fontFamily: FONT_NUNITO,
    '@media (max-width: 1025px)': {
      padding: '50px',
      width: '100%',
    },
    '@media (max-width: 991px)': {
      padding: '0',
      textAlign: 'center',
    }
  },
  headingText: {
    fontSize: theme.typography.pxToRem(20),
    color: COLOR_DARK_GRAY,
    fontWeight: 600,
  },
  laraHeadingText: {
    fontSize: theme.typography.pxToRem(60),
    color: COLOR_TEXT,
    fontWeight: 700,
    lineHeight: 1,
    '@media (max-width: 991px)': {
      fontSize: theme.typography.pxToRem(40),
    }
  },
  subheadingText: {
    fontSize: theme.typography.pxToRem(20),
    color: COLOR_HOME_TEXT,
    marginTop: theme.spacing(2),
  },
  loginAsset: {
    padding: theme.typography.pxToRem(20),
    '@media (max-width: 991px)': {
      display: 'none',
    }
  },
  loginImage: {
    width: "500px",
    height: "600px",
  },
  submitWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    '@media (max-width: 991px)': {
      justifyContent: 'center',
    }
  },
  submitButton: {
    backgroundColor: 'transparent',
    border: `2px solid ${COLOR_PRIMARY}`,
    borderRadius: '24px',
    color: COLOR_PRIMARY,
    display: 'flex',
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    justifyContent: 'center',
    padding: '10px',
    opacity: 1,
    width: '60%',
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      color: COLOR_WHITE,
      textDecoration: 'none'
    }
  },
  dashboardButton: {
    backgroundColor: 'transparent',
    border: `2px solid ${COLOR_PRIMARY}`,
    borderRadius: '25px',
    color: COLOR_TEXT,
    display: 'flex',
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    justifyContent: 'center',
    marginRight: theme.spacing(3),
    padding: '10px',
    opacity: 1,
    width: '50%',
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      color: COLOR_WHITE,
      textDecoration: 'none'
    }
  }
}), { name: Home.name });

export default function Home(props:any) {
  const classes = useStyles();

  const history = useHistory();


  React.useEffect(()=>{
    if( isLoggedIn() ){
      history.push('/dashboard')
    }
  },[])
  return (
    <div className={classes.homeWrapper}>
      <div className={classes.loginWrapper}>
        <div className={classes.headingText}>NFT Asset Control</div>
        <div className={classes.laraHeadingText}>England</div>
        <div className={classes.laraHeadingText}>and Wales</div>
        <div className={classes.laraHeadingText}>Cricket Board</div>
        <div className={classes.subheadingText}>Create and Manage NFT Assets</div>
        { props.dashboardPage ?
          <div className={classes.submitWrapper}>
            <Action className={classes.dashboardButton} href='/new-asset/asset-info'>
              Create Asset
            </Action>
            <Action className={classes.dashboardButton} href='/all-assets'>
              Existing Assets
            </Action>
          </div>
        :
          <div className={classes.submitWrapper}>
            <Action className={classes.submitButton} href='/login'>
              Login
            </Action>
          </div>
        }
      </div>
      {/* <div style={{
        width: "95vw",
        bottom:"0",textAlign:"center",position:"absolute", marginBottom:"10px", fontWeight:600}}>     Powered by <a href="https://vlinder.io/" target="_blank"> vlinder</a></div> */}

          <div style={{
        width: "95vw",
        bottom:"0",textAlign:"center",position:"absolute", marginBottom:"20px", fontWeight:600}}>   
          Powered by <a href="https://vlinder.io/" target="_blank"> vlinder</a>
        </div>
        {/* <img src ={vlinderLogo}/> */}
      <div className={classes.loginAsset}>
        <img className={classes.loginImage} src={homeImage} alt="login-image" />
      </div>
      {/* <div className={classes.cardContainer}>
        <Action className={classes.card} href='/new-asset/asset-info'>
          <div className={classes.cardPlaceholder}>
            <div className={classes.cardContent}>
              <span>Create Asset</span>
              <img src={createAssetIcon} className={classes.cardPlaceholderImage} />
            </div>
          </div>
        </Action>
        <Action className={classes.card} href='/all-assets'>
          <div className={classes.cardPlaceholder}>
            <div className={classes.cardContent}>
              <span>View All Assets</span>
              <img src={viewAssetIcon} className={classes.cardPlaceholderImage} />
            </div>
          </div>
        </Action>
      </div> */}
    

    </div>
    
  );
}
