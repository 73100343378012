import Page from './Page';
import PageTemplate from '../templates/PageTemplete';
import AddAsset from '../organisms/AddAsset';

export default function DashboardPage() {
  return (
    <Page>
      {/* <PageTemplate> */}
        <AddAsset />
      {/* </PageTemplate> */}
    </Page>
  );
}