import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import NavBar, { NAVBAR_HEIGHT } from '../molecules/Navbar';
import { COLOR_WHITE } from '../styles/colors';
import { FONT_NUNITO } from '../styles/fonts';

interface StandalonePageProps {
  children:any;
}
const useStyles = makeStyles(theme => ({
  standalonePage: {
    background: COLOR_WHITE,
    display: 'flex',
    fontFamily: FONT_NUNITO,
    width: '100%',
  },
  pageContainer: {
    flexGrow: 1,
    width: '100%',
  },
  container: {
    boxSizing: 'border-box',
    padding: '0px',
    width: `100vw`,
  },
}), { name: StandalonePage.name });

export default function StandalonePage (props:StandalonePageProps) {
  const {
    children,
  } = props;
  const classes = useStyles();

  return(
    <div className={classes.standalonePage}>

      <main className={classes.pageContainer}>
        <Container
          className={classes.container}
          maxWidth={false}
        >
          {children}
        </Container>
      </main>
    </div>
  );
}