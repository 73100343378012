import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface PageTemplateProps {
  className?: string;
  children: any;
}

const useStyles = makeStyles(theme => ({
  pageTemplate: {
    width: '100%',
    minHeight: 'calc(100vh - 90px)',
    display: 'flex',
  },
}), { name: PageTemplate.name });

export default function PageTemplate (props: PageTemplateProps) {
  const classes = useStyles();
  const { children, className } = props;

  return (
    <div className={clsx(classes.pageTemplate, className)}>
      {children}
    </div>
  );
}