import { combineReducers } from 'redux';
import userReducer, { UserState } from './userReducer';

export interface State {
  user: UserState;
}

export default combineReducers({
  user: userReducer,
});
