import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import DashboardPage from '../pages/DashboardPage';
import LoginPage from '../pages/LoginPage';
import AddAssetPage from '../pages/AddAssetPage';
import AllAssetPage from '../pages/AllAssetPage';
import LandingPage from '../pages/LandingPage';
import ScrollToTop from '../pages/ScrollToTop';

export default function Router () {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Redirect exact from='/' to='/home' />
        <Route exact path='/home'>
          <LandingPage />
        </Route>
        <Route exact path='/login'>
          <LoginPage />
        </Route>
        <PrivateRoute exact path='/dashboard'>
          <DashboardPage />
        </PrivateRoute>
        <PrivateRoute path='/new-asset'>
          <Redirect exact from='/new-asset' to='/new-asset/asset-info' />
          <PrivateRoute exact path='/new-asset/asset-info'>
            <AddAssetPage />
          </PrivateRoute>
        </PrivateRoute>
        <PrivateRoute exact path='/all-assets/:tab'>
          <AllAssetPage />
        </PrivateRoute>
        <PrivateRoute exact path='/all-assets'>
          <Redirect to='/all-assets/0' />
        </PrivateRoute>
        <PrivateRoute path='/'>
          <LandingPage />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
}