import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '../services/user';

interface PrivateRouteProps {
  children: any;
  [key: string]: unknown;
}

export default function PrivateRoute (props: PrivateRouteProps) {
  const {
    children,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={props => {
        return isLoggedIn()
          ? children
          : <Redirect to={`/login?redirect=${window.location.pathname}`} />;
      }}
    />
  );
};
