import { makeStyles } from '@material-ui/core/styles';
import Page from './Page';
import PageTemplate from '../templates/PageTemplete';
import Home from '../organisms/Home';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
  }
}), { name: PageTemplate.name });

export default function DashboardPage() {
  const classes = useStyles();

  return (
    <Page containerClass={classes.container}>
      <PageTemplate>
        <Home />
      </PageTemplate>
    </Page>
  );
}