import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  COLOR_PRIMARY,
  COLOR_TEXT,
} from '../styles/colors';
import { FONT_NUNITO } from '../styles/fonts';

interface InputProps {
  className?: string;
  inputFieldClassName?: string;
  labelClassName?: string;
  labelShrinkClassName?: string;
  type?: 'text' | 'password' | 'date';
  label?: string;
  placeholder?: string;
  value: string;
  error?: string;
  fontSize?: number;
  textColor?: string;
  autoFocus?: boolean;
  multiLine?: boolean;
  disabled?: boolean;
  rows?: number;
  endAdornment?: any;
  onChange?: (value:string, event:any) => void;
  onBlur?: (value:string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    borderRadius: theme.spacing(1.5),
    paddingRight: '0px',

    '&:before': {
      borderWidth: '2px',
      borderColor: COLOR_PRIMARY,
    },
    '&:after': {
      borderWidth: '2px',
      borderColor: COLOR_PRIMARY,
    },

    '&:hover': {
      borderColor: COLOR_PRIMARY,

      '&:before': {
        borderWidth: '2px',
      },
    },

    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    }
  },

  inputLabel: {
    top: (props:InputProps) => theme.typography.pxToRem(-1 * (16 - (props.fontSize || 16))),
    left: theme.spacing(0.5),
    fontFamily: FONT_NUNITO,
    fontSize: (props:InputProps) => theme.typography.pxToRem(props.fontSize || 16),
    lineHeight: 1,
    color: COLOR_TEXT,

    '&$inputLabelFocused': {
      color: COLOR_PRIMARY,
      fontWeight: 600,
    }
  },
  inputLabelShrink: {
    fontWeight: 600,
    fontSize: (props:InputProps) => theme.typography.pxToRem((props.fontSize || 16) * 0.8),
    color: COLOR_PRIMARY,
  },
  inputLabelFocused: {},

  inputField: {
    fontFamily: FONT_NUNITO,
    fontSize: (props:InputProps) => theme.typography.pxToRem(props.fontSize || 16),
    lineHeight: 1,
    color: (props:InputProps) => props.textColor ? props.textColor : COLOR_TEXT,
  },
}), { name: Input.name });

export default function Input (props: InputProps) {
  const {
    className,
    inputFieldClassName,
    labelClassName,
    labelShrinkClassName,
    type = 'text',
    label,
    placeholder,
    value,
    error,
    textColor,
    autoFocus = false,
    multiLine = false,
    disabled = false,
    rows = 5,
    endAdornment,
    onChange = (value:string, event:any) => {},
    onBlur = (value:string) => {},
  } = props;
  const classes = useStyles(props);

  return (
    <TextField
      className={className}
      InputProps={{
        classes: {
          root: classes.input,
          input: clsx(classes.inputField, inputFieldClassName),
        },
        endAdornment: <InputAdornment position={endAdornment ? endAdornment.position : 'end'}>{endAdornment ? endAdornment.label : ''}</InputAdornment>
      }}
      InputLabelProps={{
        className: clsx(classes.inputLabel, labelClassName),
        classes: {
          shrink: clsx(classes.inputLabelShrink, labelShrinkClassName),
          focused: classes.inputLabelFocused,
        },
      }}
      type={type}
      variant='outlined'
      placeholder={placeholder}
      error={!!error}
      helperText={error}
      value={value}
      autoFocus={autoFocus}
      multiline={multiLine}
      rows={rows}
      disabled={disabled}
      onChange={(event:any) => onChange(event.target.value, event)}
      onBlur={(event:any) => onBlur(event.target.value)}
    />
  );
}
