//@ts-nocheck

import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import PublishIcon from '@material-ui/icons/Publish';
import { COLOR_BACKGROUND_LIGHT, COLOR_DARK_GRAY } from '../styles/colors';


interface FileDropzoneProps {
  className?: string;
  selectedFile: any,
  onChange?: (value:any) => void;
  handleOnDelete?: (value:any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    position: 'relative',
  },
  overlayDrop: {
    backgroundColor: 'transparent',
    cursor: 'not-allowed',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
  },
  dropzoneArea: {
    backgroundColor: COLOR_BACKGROUND_LIGHT,
    minHeight: '100px',
    padding: theme.spacing(2),
  },
  textContainer: {
    // display: (props:FileDropzoneProps) => props.selectedFile ? 'none' : 'block',
    '& .MuiDropzoneArea-text': {
      margin: '14px 0',
    },
    '& .MuiTypography-h5': {
      color: COLOR_DARK_GRAY,
      fontSize: theme.typography.pxToRem(12),
    },
    '& .MuiDropzoneArea-icon': {
      color: COLOR_DARK_GRAY,
      height: '40px',
      width: '45px',
    }
  },
  previewGrid: {
    // width: '100%',
    height: '200px',
  },
  previewGridContainer: {
    display: 'block',
  },
  spacingXs: {
    margin: 0,

    '& $MuiGrid-item': {
      padding: 0,
    }
  },
  previewGridItem:{
    padding: '0 !important',

    '& > img': {
      height: '200px',
      width: '200px',
    }
  },
  spacingXsItem: {
    padding: 0,
    // width: '200px',
    height: '200px',
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    width: 'auto',
    height: '100%'
  },
  previewContainer: {
    container: 'true',
    width: '100%',
    height: '100%',
  },
  preview: {
  },
  previewImg: {
    height: '200px',
    // width: '200px',
  },
  '.MuiDropzonePreviewList-image': {
    height: '200px',
    // width: '200px',
  }
}), { name: FileDropzone.name });

export default function FileDropzone (props: FileDropzoneProps) {
  const {
    className,
    selectedFile,
    onChange = (value:any) => {},
    handleOnDelete = (value:any) => {},
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.dropZone}>
      <DropzoneArea
        classes={{
          root:clsx(classes.dropzoneArea, className),
          textContainer:classes.textContainer,
        }}
        previewGridProps={{
          container: {
            classes: {
              root: classes.previewGrid,
              'spacing-xs-8': (clsx('MuiGrid-spacing-xs-12', classes.spacingXs))
            }
          },
          item: {
            classes: {
              root: classes.previewGridItem,
              item: classes.spacingXsItem,
              'grid-xs-4': 'MuiGrid-grid-xs-12'
            }
          },
        }}
        previewGridClasses={{
          container: classes.previewContainer,
          item: 'xs-12',
          image: classes.img,
        }}
        showPreviewsInDropzone={false}
        acceptedFiles={['image/*']}
        dropzoneText='PNG, GIF or JPEG'
        filesLimit={1}
        onChange={(files) => onChange(files[0])}
        onDelete={(file) => handleOnDelete(file)}
        Icon={PublishIcon}
      />
      { selectedFile &&
        <div className={classes.overlayDrop}></div>
      }

    </div>
  );
}
