import {FormEvent, useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles} from '@material-ui/core/styles';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import { logIn, saveUser } from '../services/user';
import { isLoggedIn } from '../services/user';

import { getQueryParameter } from '../services/route-helper';
import { FONT_NUNITO } from '../styles/fonts';
import loginImage from '../assets/images/lara_login_image2.png';
import {
  COLOR_WHITE,
  COLOR_LOGIN_BTN,
  COLOR_LOGIN_BG,
  COLOR_TEXT
} from '../styles/colors';

const useStyles = makeStyles(theme => ({
  loginConatiner: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    padding: '50px 0px',
    width: '75%',
   

    '& .MuiFormHelperText-root.Mui-error': {
      color: COLOR_WHITE
    },

    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline ': {
      borderColor: COLOR_WHITE
    }
  },
  loginAsset: {
    display: 'flex',
    // width: '100%',

    '@media (max-width: 991px)': {
      display: 'none',
    }

  },
  loginImage: {
    height: "600px",
    width: "500px",
    borderRadius: '21px 0px 0px 21px',
    '@media (max-width: 1025px)': {
      width: "400px",
    },
  },
  loginForm: {
    // backgroundColor: COLOR_LOGIN_BG,
    backdropFilter:"blur(20px)",
    background:"linear-gradient(109.13deg, rgba(239, 239, 239, 0.03) 3.12%, rgba(239, 239, 239, 0.1) 89.96%, rgba(239, 239, 239, 0.174015) 89.96%);",
    borderColor: COLOR_WHITE,
    borderRadius: '0 21px 21px 0',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px',
    width: '100%',

    '@media (max-width: 1025px)': {
      padding: '30px',
    },
    '@media (max-width: 991px)': {
      padding: '10px',
    }
  },
  title: {
    fontSize: theme.typography.pxToRem(28),
    margin: 0,
    color: COLOR_WHITE,
  },
  input: {
    marginTop: theme.spacing(3),
  },

  submitWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  submitButton: {
    backgroundColor: 'transparent',
    borderColor: COLOR_LOGIN_BTN,
    borderRadius: '25px',
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(24),
    margin: '0 auto',
    padding: '10px 32px',
    opacity: 1,
    width: '70%',

    '&:hover': {
      backgroundColor: COLOR_LOGIN_BTN,
      color: COLOR_WHITE,
    }
  },

  '@media (max-height: 800px)': {
    footer: {
      position: 'relative',
      bottom: 'auto',
      marginTop: theme.spacing(8),
    },
  },

  '@media (max-width: 400px)': {
    submitButton: {
      padding: theme.spacing(2, 3),
    },
    forgotPassword: {
      padding: 0,
    },
  },
}), { name: LoginForm.name });

export default function LoginForm () {
  
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);

  useEffect(()=>{
    if( isLoggedIn() ){
      history.push('/dashboard')
    }
  },[])

  function onLogIn (event:FormEvent) {
    console.log("coming here");

    event.preventDefault();
console.log(checkFieldsAreValid(email, password));

    if (checkFieldsAreValid(email, password)) {
      setIsLoading(true);

      logIn(email, password)
        .then((data: any) => data && data.userManagementControllerLogin as any)
        .then((userData:any) => {
          if (userData.token) {
            saveUser(userData.token);
            const redirectPath = getQueryParameter('redirect');
            history.push(redirectPath ? redirectPath : '/dashboard');
          } else {
            showToast('Failed to login', ToastType.ERROR);
          }
        })
        .catch((error:any) => {
          console.error(error);
          showToast('Failed to login', ToastType.ERROR);
        })
        .finally(() => setIsLoading(false));
    }
  }

  function checkFieldsAreValid (email:string, password:string) {
    // let { valid, error } = validatePassword(password);
    let valid = validateEmail(email);
    valid = validatePassword(password);
    return valid;
  }

  function validateEmail(email:string) {
    let valid = true;

    if (email.length === 0) {
      setEmailError('Please enter Email');
    } else if (!email.includes('@')) {
      setEmailError('Email is invalid');
      valid = false;
    } else {
      setEmailError('');
    }
    return valid;
  }

  function validatePassword(password:string) {
    let valid = true;

    if (password.length === 0) {
      setPasswordError('Please enter Password');
    } else {
      setPasswordError('');
    }
    return valid;
  }

  function showToast (message:string, toastType:ToastType = ToastType.SUCCESS) {
    setToastMessage(message)
    setToastVisible(true);
    setToastType(toastType);
  }

  return (
    <div className={classes.loginConatiner}>
      <div className={classes.loginAsset}>
        <img className={classes.loginImage} src={loginImage} alt="login-image" />
      </div>
      <form
        className={classes.loginForm}
        onSubmit={onLogIn}
      >
        <Loader inProgress={isLoading} />

        <div className={classes.title}>LOGIN</div>

        <Input
          className={classes.input}
          placeholder='Email'
          value={email}
          error={emailError}
          onChange={(value:string) => setEmail(value.trim())}
          onBlur={(value:string) => validateEmail(value)}
          textColor={COLOR_WHITE}
        />

        <Input
          className={classes.input}
          type='password'
          placeholder='Password'
          value={password}
          error={passwordError}
          onChange={(value:string) => setPassword(value.trim())}
          onBlur={(value:string) => validatePassword(value)}
          textColor={COLOR_WHITE}
        />

        <div className={classes.submitWrapper}>
          <Button
            className={classes.submitButton}
            type='submit'
            primary
            disabled={!!emailError || !!passwordError}
          >
            Login
          </Button>
        </div>
      </form>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  )
}
