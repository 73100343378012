import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles, fade, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logout, isLoggedIn } from '../services/user';
import {
  COLOR_WINE,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_DARK_GRAY,
  COLOR_PRIMARY
} from '../styles/colors';

interface NavBarProps {
  className?: string;
}

export const NAVBAR_HEIGHT = 90;

const useStyles = makeStyles((theme: Theme) => ({
  navBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 999,
  },

  drawerControlContainer: {
    width: '71px',
    height: '64px',
    background: COLOR_WHITE,
  },

  appBar: {
    left: 0,
    right: 'auto',
    width: '100%',
    background: COLOR_WHITE,
    color: COLOR_PRIMARY,
    position: 'fixed',
    top: '0',
  },
  grow: {
    flexGrow: 1,
  },

  toolbar: {
    minHeight: `${NAVBAR_HEIGHT}px`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: theme.typography.pxToRem(14),
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuIcon: {
    width: '22px',
    height: '22px',
    display: 'inline-block',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    userSelect: 'none',
    position: 'relative',

    '&:before': {
      content: '""',
      width: '100%',
      height: '2px',
      background: COLOR_DARK_GRAY,
      position: 'absolute',
      top: '5px',
      left: 0,
    },
    '&:after': {
      content: '""',
      width: '100%',
      height: '2px',
      background: COLOR_DARK_GRAY,
      position: 'absolute',
      bottom: '5px',
      left: 0,
    },
  },

  actions: {
    display: 'flex',
  },
  action: {
    overflow: 'visible',
    position: 'relative',
    marginLeft: theme.spacing(1),
    transition: 'color 0.3s',
    color: COLOR_PRIMARY,

    '&:first-of-type': {
      marginLeft: '0',
    },

    '&:before': {
      content: '""',
      display: 'none',
      width: '100%',
      height: '5px',
      position: 'absolute',
      bottom: '-34px',
      left: 0,
      background: COLOR_PRIMARY,
    },

    '&:hover': {
      textDecoration: 'none',
    },
  },
  actionSelected: {
    '&:before': {
      display: 'block',
    },

    '& $actionIcon': {
      color: COLOR_PRIMARY,
    },
  },
  actionIcon: {
    flexShrink: 0,
    width: '22px',
    height: '22px',
    marginRight: 0,
    color: COLOR_TEXT,
    transition: 'color 0.3s',

    'a:hover &': {
      color:COLOR_PRIMARY,
    },
  },
  actionText: {},

  title: {
    cursor: 'pointer',
  },
  gorw: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}), { name: NavBar.name });

export default function NavBar (props:NavBarProps) {
  const {
    className
  } = props;
  const classes = useStyles();
  const history = useHistory();

  function onLogout() {
    logout();
    history.push('/login');
  }

  return (
    <nav className={clsx(classes.navBar)}>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar)}
      >
        <Toolbar className={classes.toolbar}>
          {
            isLoggedIn() &&
            <Typography
            className={classes.title}
            variant='h6'
            noWrap
            onClick={() => history.push('/dashboard')}
          >
            HOME
          </Typography>
          }
          
          <div  style={{textAlign: "center",
    position: "absolute",
    left: "45%",
   }} > 
          <div style={{display:'flex', textAlign:"center", justifyContent:"center",alignContent:"center", margin:" 0 auto", alignItems:"center"}}>

          <Typography
            // className={classes.title}
            variant='h5'
            noWrap
            
          >
            NFT Asset Control
          </Typography>
           
        
          </div>
       </div>

          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder='Search…'
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}

          <div className={classes.grow} />
          {
               isLoggedIn() && 
               <div className={classes.actions}>
               <IconButton
                   className={classes.action}
                   edge='end'
                   onClick={onLogout}
                 >
                 <div style={{display:'flex', flexDirection:'column', justifyContent:"center",alignContent:"center", fontSize:"16px"}}>
                  <div style={{display:"flex", justifyContent:"center"}}>
                  <ExitToAppIcon />
                  </div>
                    
                   Logout
                 </div>
                 </IconButton>
               </div>
          }
      
        </Toolbar>
      </AppBar>
    </nav>
  );
}
